<template>
    <div class="page-box">
        <div class="project-info">
            <div class="project-list">
                <h3>
                    赞助汇总表

                </h3>
                <el-table
                        :data="tableData"
                        header-row-class-name="table-header"
                        style="width: 100%"
                        height="650"
                >
                    <el-table-column label="序号" align="center" >
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.$index + 1 }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column  label="招商学会">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.society }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="企业名称">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ !scope.row.sid ? "--" : scope.row.name }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column  label="企业联系人" >
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ !scope.row.sid ? "--" : scope.row.contacts }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column  label="招商函发出日期">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ !scope.row.sid ? "--" : scope.row.dissemination_date }}
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column label="会议基金">
                        <el-table-column label="拟赞助金额（万元）">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ !scope.row.sid ? "--" : scope.row.sponsorship_metting }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column  label="协议签订金额（万元）">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ !scope.row.sid ? "--" : scope.row.agreement_metting }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column  label="协议签订日期">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ !scope.row.sid ? "--" : scope.row.agreement_date }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column  label="到款金额（万元）">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ !scope.row.sid ? "--" : scope.row.received_metting }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column  label="到款日期">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ !scope.row.sid ? "--" : scope.row.received_date }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column   label="管理费+税费（万元）">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ !scope.row.sid ? "--" : scope.row.management_amount }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column   label="会议可用金额 （万元）">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ scope.row.meet_run }}
                                </div>
                            </template>
                        </el-table-column>
                        <!--                            <el-table-column-->
                        <!--                                width="150"-->
                        <!--                                label="会议管理费+税金优惠申请状态 "-->
                        <!--                            >-->
                        <!--                              <template slot-scope="scope">-->
                        <!--                                <div class="table-item">-->
                        <!--                                  {{ scope.row.sid ? (scope.row.management_amount_state | state) : '&#45;&#45;' }}-->
                        <!--                                </div>-->
                        <!--                              </template>-->
                        <!--                            </el-table-column>-->
                    </el-table-column>

                    <!--            <el-table-column width="180" fixed="right" label="操作">-->
                    <!--              <template>-->
                    <!--                <div class="table-operate">-->
                    <!--                  <el-button type="text" size="small">编辑</el-button>-->
                    <!--                </div>-->
                    <!--              </template>-->
                    <!--            </el-table-column>-->
                </el-table>
                <el-table
                        :data="total"
                        class="table-total"
                        :show-header="false"
                        :span-method="objectSpanMethod"
                        style="width: 100%; background: #f2f2f2"
                >
                    <el-table-column width="100">
                        <template>
                            <div class="table-item">总计</div>
                        </template>
                    </el-table-column>
                    <el-table-column width="300">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.name2 }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column>
                        <template slot-scope="scope">
                            <div class="table-item">
                                <span>{{ scope.row.num2 }}</span>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        components: {

        },
        name: "list",
        data() {
            return {
                id: "",
                tableData: [],
                total: [
                    {
                        name: "拟定协议金额（万元）",
                        num: 0,
                        name2: "拟定会议基金金额（万元）",
                        num2: 0,
                        name3: "拟定专项基金金额（万元）",
                        num3: 0,
                        isEdit: false,
                    },
                    {
                        name: "协议签订金额（万元）",
                        num: 0,
                        name2: "会议基金签订金额（万元）",
                        num2: 0,
                        name3: "专项基金签订金额（万元）",
                        num3: 0,
                        isEdit: false,
                    },
                    {
                        name: "协议到款金额（万元）",
                        num: 0,
                        name2: "会议基金到款金额（万元）",
                        num2: 0,
                        name3: "专项基金到款金额（万元）",
                        num3: 0,
                        isEdit: false,
                    },
                    {
                        name: "协议可用金额（万元）",
                        num: 0,
                        name2: "会议基金可用金额（万元）",
                        num2: 0,
                        name3: "专项基金可用金额（万元）",
                        num3: 0,
                        isEdit: true,
                    },
                ],
            };
        },
        created() {
            this.id = this.$route.query.id;
            this.init();
        },

        methods: {
            //  合并单元格
            objectSpanMethod({row, column, rowIndex, columnIndex}) {
                console.log(row, column);
                if (columnIndex === 0) {
                    if (rowIndex % 4 === 0) {
                        return {
                            rowspan: 4,
                            colspan: 1,
                        };
                    } else {
                        return {
                            rowspan: 0,
                            colspan: 0,
                        };
                    }
                }
            },
            //  页面加载时请求
            init() {
                this.$api.apiContent
                    .getTableX1({
                        id: this.id,
                        type: 1,
                    })
                    .then((res) => {
                        res.data.first_list.map((item) => {
                            item.agreement_amount = parseFloat(
                                Number(item.agreement_amount).toFixed(6)
                            );
                            item.agreement_metting = parseFloat(
                                Number(item.agreement_metting).toFixed(6)
                            );
                            item.agreement_run = parseFloat(
                                Number(item.agreement_run).toFixed(6)
                            );
                            item.agreement_run_amount = parseFloat(
                                Number(item.agreement_run_amount).toFixed(6)
                            );
                            item.agreement_self = parseFloat(
                                Number(item.agreement_self).toFixed(6)
                            );
                            item.amount = parseFloat(Number(item.amount).toFixed(6));
                            item.available_meeting_amount = parseFloat(
                                Number(item.available_meeting_amount).toFixed(6)
                            );
                            item.fukuan_amount = parseFloat(
                                Number(item.fukuan_amount).toFixed(6)
                            );
                            item.management_amount = parseFloat(
                                Number(item.management_amount).toFixed(6)
                            );
                            item.meet_run = parseFloat(Number(item.meet_run).toFixed(6));
                            item.received_amount = parseFloat(
                                Number(item.received_amount).toFixed(6)
                            );
                            item.received_metting = parseFloat(
                                Number(item.received_metting).toFixed(6)
                            );
                            item.received_self = parseFloat(
                                Number(item.received_self).toFixed(6)
                            );
                            item.sponsorship_amount = parseFloat(
                                Number(item.sponsorship_amount).toFixed(6)
                            );
                            item.sponsorship_metting = parseFloat(
                                Number(item.sponsorship_metting).toFixed(6)
                            );
                            item.sponsorship_self = parseFloat(
                                Number(item.sponsorship_self).toFixed(6)
                            );
                        });
                        res.data.second_list.map((item) => {
                            item.contract_amount = parseFloat(
                                Number(item.contract_amount).toFixed(6)
                            );
                            item.contract_meeting = parseFloat(
                                Number(item.contract_meeting).toFixed(6)
                            );
                            item.contract_self = parseFloat(
                                Number(item.contract_self).toFixed(6)
                            );
                            item.payment_amount = parseFloat(
                                Number(item.payment_amount).toFixed(6)
                            );
                            item.payment_meeting = parseFloat(
                                Number(item.payment_meeting).toFixed(6)
                            );
                            item.payment_self = parseFloat(
                                Number(item.payment_self).toFixed(6)
                            );
                        });
                        this.tableData = res.data.first_list;
                        this.tableData2 = res.data.second_list;

                        this.total[0].num = parseFloat(res.data.total.sponsorship.toFixed(6));
                        this.total[0].num2 = parseFloat(
                            res.data.total.sponsorship_meet.toFixed(6)
                        );
                        this.total[0].num3 = parseFloat(
                            res.data.total.sponsorship_self.toFixed(6)
                        );

                        this.total[1].num = parseFloat(res.data.total.signing.toFixed(6));
                        this.total[1].num2 = parseFloat(
                            res.data.total.signing_meet.toFixed(6)
                        );
                        this.total[1].num3 = parseFloat(
                            res.data.total.signing_self.toFixed(4)
                        );

                        this.total[2].num = parseFloat(res.data.total.received.toFixed(6));
                        this.total[2].num2 = parseFloat(
                            res.data.total.received_meet.toFixed(6)
                        );
                        this.total[2].num3 = parseFloat(
                            res.data.total.received_self.toFixed(6)
                        );

                        this.total[3].num = parseFloat(res.data.total.available.toFixed(6));
                        this.total[3].num2 = parseFloat(
                            res.data.total.available_meet.toFixed(6)
                        );
                        this.total[3].num3 = parseFloat(
                            res.data.total.available_self.toFixed(6)
                        );
                    })
                    .catch(() => {
                        this.tableData = [];
                        this.tableData2 = [];
                    });
            },
            //  返回
            back() {
                this.$router.go(-1);
            },
        },
    };
</script>

<style scoped lang="scss">
    .page-box {
        .page-title {
            height: 40px;
            margin-bottom: 10px;

            span {
                font-size: 18px;
                line-height: 36px;
                color: #999999;

                b {
                    color: #333;
                    font-weight: normal;
                }
            }

            div {
                color: #3b77e7;
                font-size: 20px;
                cursor: pointer;

                i {
                    display: block;
                    width: 24px;
                    height: 24px;
                    text-align: center;
                    line-height: 24px;
                    margin-right: 5px;
                    border-radius: 100%;
                    color: #fff;
                    font-size: 14px;
                    background-color: #3b77e7;
                }
            }
        }

        .project-info {
            width: 100%;
            background-color: #ffffff;
            border-radius: 6px;
            overflow: hidden;

            .project-condition {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 24px 28px 16px;
                border-bottom: 2px solid #f5f5f5;

                .title-tab {
                    .active {
                        background: #3b77e7;
                        color: #fff;
                    }

                    .settlement {
                        margin-left: 10px;
                    }
                }

                .el-select {
                    margin-right: 20px;
                }

                .el-input {
                    margin: 0 20px;
                    width: 268px;
                }
            }

            .project-list {
                padding: 0px 28px 30px;
                min-height: 700px;

                .remerk-container {
                    margin-top: 30px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;

                    .remerk-content {
                        margin-bottom: 30px;
                    }
                }

                .share-img {
                    width: 200px;
                    height: 200px;
                }

                h3 {
                    text-align: center;
                    line-height: 40px;
                    position: relative;
                    margin: 30px 0;
                    font-size: 28px;

                    div {
                        position: absolute;
                        top: 0;
                        right: 0;
                        display: flex;
                        align-items: center;
                    }
                }

                .jiange {
                    height: 30px;
                }

                .table-id {
                    text-align: center;
                    font-size: 16px;
                    color: #666666;
                }

                .table-item {
                    text-align: center;
                    font-size: 16px;
                    color: #666666;

                    .date {
                        width: 130px;
                        height: 45px;
                        border: 1px solid #f1f1f1;
                        line-height: 45px;
                    }
                }

                .table-operate {
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    font-size: 16px;
                    color: #666666;

                    .el-button {
                        margin-left: 0px;
                        font-size: 16px;
                    }
                }

                .page-all {
                    text-align: center;
                    padding: 30px 0 0;
                }
            }
        }
    }

    .dialog-title {
        font-size: 26px;
        display: block;
        line-height: 30px;
        margin-top: 20px;
    }

    .editForm {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        .dialog-container {
            width: 100%;
            padding: 0 20px;
            margin-bottom: 20px;
            font-size: 20px;
            color: #333333;

            &.active {
                width: 100%;

                .el-textarea {
                    flex: 1;
                    margin: 0 10px;
                }
            }

            div {
                width: 120px;
                flex-shrink: 0;
            }

            .el-input,
            .el-radio-group,
            .el-textarea,
            .el-textarea__inner {
                flex-grow: 1;
                margin: 0 10px;
                font-size: 16px;
            }
        }
    }

    .editFlForm {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        .dialog-tip {
            font-size: 16px;
            color: #333333;
        }

        .dialog-container {
            padding: 0 20px;
            margin-bottom: 20px;
            font-size: 20px;
            color: #333333;

            &.active {
                width: 100%;

                .el-textarea {
                    flex: 1;
                    margin: 0 10px;
                }
            }

            div {
                flex-shrink: 0;
                font-size: 20px;
                font-weight: bold;
            }

            .el-input,
            .el-radio-group {
                width: 80px;
                margin: 0 10px;
                font-size: 16px;
            }
        }
    }

    .dialog-footer {
        .el-button {
            width: 120px;
            font-size: 16px;
        }
    }
</style>
